<template>
  <div class="content-card-new-ones">
    <div class="content-card-new-ones-slider">
      <swiper class="swiper-card-new-ones" :options="swiperCardNewOnes">
        <swiper-slide v-for="(image, index) in vehicle.images_path" :key="index">
          <router-link tag="a" :to="{name: 'vehicleUsedDetail', params: { slug: vehicle.slug }}">
            <div class="swiper-card-new-ones-image" :style="{'background': `url('${image}')`}"></div>
          </router-link>
        </swiper-slide>
        <div class="swiper-pagination swiper-pagination-card-new-ones" slot="pagination"></div>
        <div class="swiper-button-prev swiper-button-prev-card-new-ones" slot="button-prev">
          <svg xmlns="http://www.w3.org/2000/svg" width="10.041" height="17.091" viewBox="0 0 10.041 17.091"><g transform="translate(10.041 17.091) rotate(180)"><path d="M9.768,7.879,2.162.273A.937.937,0,0,0,.84.273L.28.833a.936.936,0,0,0,0,1.322L6.667,8.542.273,14.936a.937.937,0,0,0,0,1.322l.56.56a.937.937,0,0,0,1.322,0L9.768,9.205a.944.944,0,0,0,0-1.327Z" fill="#fff"/></g></svg>
        </div>
        <div class="swiper-button-next swiper-button-next-card-new-ones" slot="button-next">
					<svg style="transform: rotate(180deg)" xmlns="http://www.w3.org/2000/svg" width="10.041" height="17.091" viewBox="0 0 10.041 17.091"><g transform="translate(10.041 17.091) rotate(180)"><path d="M9.768,7.879,2.162.273A.937.937,0,0,0,.84.273L.28.833a.936.936,0,0,0,0,1.322L6.667,8.542.273,14.936a.937.937,0,0,0,0,1.322l.56.56a.937.937,0,0,0,1.322,0L9.768,9.205a.944.944,0,0,0,0-1.327Z" fill="#fff"/></g></svg>
        </div>
      </swiper>
    </div>
    <router-link tag="a" :to="{name: 'vehicleUsedDetail', params: { slug: vehicle.slug }}" class="content-card-new-ones-description">
      <div class="content-brand">
        <h2 class="name">{{ vehicle.version_name }}</h2>
        <h3 class="model">{{ vehicle.vehicle_used_model.name }}</h3>
      </div>
      <div class="content-price">
        <p>{{ vehicle.price|money }}</p>
      </div>
      <div class="content-year-km">
        <p>{{ vehicle.year_factory }}/{{ vehicle.year_model }}</p>
        <p>{{ vehicle.km }} KM</p>
      </div>
    </router-link>
    <button class="btn-cotation" @click="toggleFormFixed()">{{ $t("vehicle_used_details.button_cotation")|stripHtml }}</button>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { mapActions } from "vuex";

export default {  
  props: {
    vehicle: Object,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  methods: {
    ...mapActions('Site', ['toggleFormFixed']),
  },
  data() {
    return {
      swiperCardNewOnes: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        speed: 800,
        watchOverflow: true,
        clickable: true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: ".swiper-pagination-card-new-ones",
          type: "bullets",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next-card-new-ones",
          prevEl: ".swiper-button-prev-card-new-ones",
        },
      },
    };
  },
};
</script>

<style lang="scss">
.content-card-new-ones {
  margin-bottom: 50px;
  background: var(--white);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
  &-slider {
    .swiper-card-new-ones {
      a {
        display: block;
        width: 100%;
      }
			&-image {
				height: 193px;
				width: 100%;
				background-repeat: no-repeat !important;
				background-position: center !important;
				background-size: cover !important;
			}
      .swiper-pagination {
				&-bullets {
					bottom: 0;
				}
				&-bullet {
					height: 4px;
					width: 12px;
					box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
					border-radius: 10px;
					background: var(--white);
					margin: 0px 1.25px;
					opacity: 1;
					&-active {
						background: var(--main-color);
					}
				}
      }
    }
  }
  &-description {
    display: block;
    padding: 15px 20px;
    border: 1px solid #f5f5f5;
    .content-brand {
      margin-bottom: 5px;
      text-align: left;
      .name {
        height: 45px;
        font-size: 18px;
        font-family: "Font Bold";
        color: #3e3e3e;
        margin-bottom: 0;
        text-transform: uppercase;
      }
      .model {
        height: 40px;
        font-size: 16px;
        font-family: "Font Regular";
        color: #707070;
        margin-bottom: 0;
        text-transform: uppercase;
      }
    }
    .content-price {
      text-align: left;
      p {
        font-size: 22px;
        font-family: "Font Bold";
        color: #3e3e3e;
        margin-bottom: 0;
      }
    }
    .content-year-km {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 14px;
        font-family: "Font Regular";
        color: #707070;
        margin-bottom: 0;
        text-transform: uppercase;
      }
    }
  }
  .btn-cotation {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--main-color);
    border: 1px solid var(--main-color);
    border-radius: 0px 0px 3px 3px;
    font-size: 16px;
    font-family: "Font Black";
    color: var(--white);
    text-transform: uppercase;
    transition: 500ms;
    &:hover,
    &:focus {
      cursor: pointer;
      background: var(--white);
      color: var(--main-color);
    }
  }
}
</style>
