<template>
    <section class="content-home-itens">
        <div class="content-home-itens-header">
            <div class="container">
            <h1 class="title" v-html="$options.filters.removeP($t('home.title_below_banner'))"></h1>
            </div>
        </div>
        <div class="content-home-itens-body container">
            <div class="content-home-itens-body-elements">
            <div class="menus">
                <h2 class="title-model">{{ $t('home.title_section_news')|stripHtml }}</h2>
                <div class="content-select-mobile d-lg-none">
                <svg xmlns="http://www.w3.org/2000/svg" width="11.351" height="7.638" viewBox="0 0 11.351 7.638"><g transform="translate(0 0)"><path d="M11.169,101.336l-.372-.426a.57.57,0,0,0-.878,0l-4.241,4.832L1.432,100.9a.58.58,0,0,0-.439-.206.581.581,0,0,0-.439.206l-.372.424a.779.779,0,0,0,0,1l5.056,5.78a.6.6,0,0,0,.44.227h0a.6.6,0,0,0,.439-.227l5.051-5.764a.769.769,0,0,0,.181-.505A.761.761,0,0,0,11.169,101.336Z" transform="translate(0 -100.698)"/></g></svg>
                <select v-model="selected">
                    <option v-for="category in categories" 
                        :value="'vehicle-new-mobile-'+ category.id"
                        :key="category.id">{{ category.name }}</option>
                </select>
                </div>
                <b-tabs class="d-none d-lg-block">
                    <template v-if="loading">
                        <div class="row">
                            <div v-for="n in 6" :key="n" class="col-sm-4" align="center">
                                    <PuSkeleton
                                    height="200px"
                                    width="250px"
                                />
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <b-tab v-for="(category, index) in categories" 
                            :key="category.id" 
                            :title="category.name" 
                            :class="{'active': index === 0}">
                            <div class="row">
                                <template v-for="vehicle in vehicles">
                                    <div 
                                    v-if="vehicle.category.slug == category.slug"
                                    class="col-12 col-sm-6 col-lg-4 col-xl-3" 
                                    :key="vehicle.id">
                                        <CardNew :vehicle="vehicle" />
                                    </div>
                                </template>
                            </div>
                        </b-tab>
                    </template>
                </b-tabs>
            </div>
            <div class="content-tab-mobile d-lg-none">
                <swiper class="swiper-card-news" 
                    v-for="(category, index) in categories" :key="index" 
                    :class="{'active': selected == 'vehicle-new-mobile-'+ category.id}"
                    :options="swiperCardNews">
                    
                    <template v-for="vehicle in vehicles">
                        <swiper-slide v-if="vehicle.category.slug == category.slug" :key="vehicle.id">
                            <CardNew :vehicle="vehicle" />
                        </swiper-slide>
                    </template>
                <!-- <div class="swiper-pagination" slot="pagination"></div> -->
                <div class="swiper-button-prev swiper-button-prev-card-news" slot="button-prev">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14.464" height="21.496" viewBox="0 0 14.464 21.496"><g transform="translate(14.464) rotate(90)"><g transform="translate(0 0)"><path d="M21.152,101.907l-.7-.808a1.08,1.08,0,0,0-1.663,0l-8.032,9.151-8.041-9.161a1.1,1.1,0,0,0-.831-.391,1.1,1.1,0,0,0-.832.391l-.7.8a1.476,1.476,0,0,0,0,1.894l9.574,10.946a1.143,1.143,0,0,0,.834.43h0a1.143,1.143,0,0,0,.831-.43l9.565-10.917a1.456,1.456,0,0,0,.344-.957A1.44,1.44,0,0,0,21.152,101.907Z" transform="translate(0 -100.698)" fill="#2f2f32"/></g></g></svg>
                </div>
                <div class="swiper-button-next swiper-button-next-card-news" slot="button-next">
                    <svg style="transform: rotate(180deg)" xmlns="http://www.w3.org/2000/svg" width="14.464" height="21.496" viewBox="0 0 14.464 21.496"><g transform="translate(14.464) rotate(90)"><g transform="translate(0 0)"><path d="M21.152,101.907l-.7-.808a1.08,1.08,0,0,0-1.663,0l-8.032,9.151-8.041-9.161a1.1,1.1,0,0,0-.831-.391,1.1,1.1,0,0,0-.832.391l-.7.8a1.476,1.476,0,0,0,0,1.894l9.574,10.946a1.143,1.143,0,0,0,.834.43h0a1.143,1.143,0,0,0,.831-.43l9.565-10.917a1.456,1.456,0,0,0,.344-.957A1.44,1.44,0,0,0,21.152,101.907Z" transform="translate(0 -100.698)" fill="#2f2f32"/></g></g></svg>
                </div>
                </swiper>
            </div>
            </div>
        </div>
    </section>
</template>
<script>
import CardNew from "@/components/cards/CardNew.vue";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        CardNew,
        Swiper,
        SwiperSlide,
    },
    directives: {
        swiper: directive,
    },
    computed: {
        ...mapGetters('VehicleNew', ['loading', 'vehicles', 'categories', 'loading']),
    },
    methods: {
        ...mapActions('VehicleNew', ['loadVehicleNews'])
    },
    mounted(){
        this.loadVehicleNews();
    },
    data(){
        return {
            swiperCardNews: {
                slidesPerView: 1,
                slidesPerGroup: 1,
                speed: 800,
                watchOverflow: true,
                clickable: true,
                autoplay: {
                delay: 5000,
                },
                pagination: {
                el: ".swiper-pagination-card-news",
                type: "bullets",
                clickable: true,
                },
                navigation: {
                nextEl: ".swiper-button-next-card-news",
                prevEl: ".swiper-button-prev-card-news",
                },
            },
            selectedCategory: null,
            selected: 'vehicle-new-mobile-1',
        }
  },
}
</script>