<template>
    <div>
      <template v-if="loading">
          <div class="row">
              <div v-for="n in 4" :key="n" class="col-12 col-sm-6 col-lg-4 col-xl-3" align="center">
                      <PuSkeleton
                      height="200px"
                      width="250px"
                  />
              </div>
          </div>
      </template>
      <template v-else>
        <section v-if="Object.keys(vehicles).length > 0 && vehicles.data.length > 0" class="content-home-new-ones">
          <div class="content-home-new-ones-itens container">
            <div class="content-home-new-ones-itens-header">
              <h2 class="title">
                {{ $t("home.title_section_useds")|stripHtml }}
              </h2>
            </div>
            <div class="content-home-new-ones-itens-body">
              <div class="row">
                <div class="col-12 col-sm-6 col-lg-4 col-xl-3" v-for="vehicle in vehicles.data" :key="vehicle.id">
                  <CardVehicleUsed :vehicle="vehicle" />
                </div>
              </div>
              <div class="content-btn">
                <router-link tag="a" :to="{name: 'vehicleUseds'}" class="btn-view-plus">
                  {{ $t("home.button_more_vehicles")|stripHtml }}
                </router-link>
              </div>
            </div>
          </div>
        </section>
      </template>
    </div>
</template>
<script>
import CardVehicleUsed from "@/components/cards/CardVehicleUsed.vue";
import { mapGetters, mapActions } from 'vuex';
export default {
    components: {
        CardVehicleUsed,
    },
    computed: {
      ...mapGetters('VehicleUsed', ['loading', 'vehicles'])
    },
    methods: {
      ...mapActions('VehicleUsed', ['loadVehicleUseds'])
    },
    mounted(){
      this.loadVehicleUseds({limit: 4})
    }
}
</script>