<template>
  <div class="home">
    <div class="content-home">
      <BannerHome />
      <!--  -->
      <SectionNews />
      <!--  -->
      <SectionSolutions />
      <!--  -->
      <SectionUseds />
      <!--  -->
      <SectionBlog :limit="3" />
    </div>
  </div>
</template>

<script>
import BannerHome from "./BannerHome.vue";
import SectionNews from './SectionNews.vue';
import SectionSolutions from './SectionSolutions.vue';
import SectionUseds from './SectionUseds.vue';
import SectionBlog from './SectionBlog.vue';

export default {
  name: 'Home',  
  components: {
    BannerHome,
    SectionNews,
    SectionBlog,
    SectionSolutions,    
    SectionUseds,
  },
}
</script>
<style lang="scss">
.content-home {
  &-itens {
    &-header {
      padding: 60px 0px;
      background: #EDEDE3;
      text-align: left;
      @media (max-width: 991px){
        padding: 20px 0px;
        text-align: center;
      }
      .title {
        position: relative;
        font-size: 40px;
        font-family: 'Font Medium';
        color: #2F2F32;
        margin-bottom: 0;
        padding-bottom: 6px;
        @media (max-width: 991px){
          font-size: 20px;
        }
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          height: 4px;
          width: 128px;
          display: block;
          background: var(--main-color);
          box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
          border-radius: 10px;
          @media (max-width: 991px){
            left: 50%;
            transform: translateX(-50%);
          }
        }
        span {
          font-family: 'Font Black';
        }
      }
    }
    &-body {
      padding-top: 30px;
      padding-bottom: 30px;
      @media (max-width: 991px){
        padding-bottom: 20px;
      }
      &-elements {
        .title-model {
          font-size: 20px;
          font-family: 'Font Medium';
          color: #2F2F32;
          margin-right: 20px;
          margin-bottom: 0;
          text-transform: uppercase;
          height: 60px;
          display: flex;
          align-items: center;
          @media (max-width: 991px){
            font-size: 16px;
            height: initial;
          }
        }
        .menus {
          display: flex;
          @media (max-width: 991px){
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
          }
          .nav {
            &-tabs {
              border: 0;
            }
            &-item {
              border: 0;
              &:last-child{
                .nav-link {
                 margin-right: 0;
                }
              }
            }
            &-link {
              position: relative;
              border: 0;
              border-radius: 0;
              border-style: none;
              border-color: inherit;
              font-size: 20px;
              font-family: 'Font Medium';
              color: #2F2F32;
              padding: 15px 30px;
              margin-right: 10px;
              transition: 500ms;
              &::after {
                content: '';
                display: block;
                position: absolute;
                bottom: -2.5px;
                left: 50%;
                transform: translateX(-50%);
                height: 5px;
                width: 18px;
                background: var(--main-color);
                border: 1px solid var(--white);
                border-radius: 5px;
                opacity: 0;
                transition: 500ms;
              }
            }
            .active {
              border: 0;
              color: var(--main-color);
              background: var(--white);
              border-radius: 10px;
              box-shadow: 3px 3px 10px rgba(0,0,0,0.16);
              &::after {
                content: '';
                opacity: 1;
              }
            }
          }
          .tab-content {
            margin-top: 60px;
          }
          .content-select-mobile  {
            height: 40px;
            width: 115px;
            position: relative;
            select {
              height: 100%;
              width: 100%;
              padding-left: 15px;
              padding-right: 30px;
              background: var(--white);
              border: 1px solid #CBCBCB;
              border-radius: 5px;
              font-size: 18px;
              font-family: 'Font Medium';
              color: var(--main-color);
              appearance: none;
              -moz-appearance: none;
              -webkit-appearance: none;
              outline: none;
            }
            svg {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 15px;
              pointer-events: none;
            }
          }
          .tabs {
            width: 100%;
          }
        }
        .content-tab-mobile {
          .swiper-card-news {
            display: none;
            .swiper-button-prev-card-news, .swiper-button-next-card-news {
              height: 50px;
              width: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #EDEDE3;
              border: 1px solid #E9E9E9;
              border-radius: 100%;
            }
            .swiper-button-prev-card-news {
              left: 0;
            }
            .swiper-button-next-card-news {
              right: 0;
            }
          }
          .active {
            display: block;
          }
        }
      }
    }
  }
  &-solutions {
    padding: 80px 0px;
    background: #2F2F32;
    @media (max-width: 991px){
      padding: 30px 0px;
    }
    &-itens {
      &-header {
        text-align: left;
        margin-bottom: 50px;
        @media (max-width: 991px){
          text-align: center;
        }
        .title {
          position: relative;
          font-size: 40px;
          font-family: 'Font Regular';
          color: var(--white);
          margin-bottom: 0;
          padding-bottom: 10px;
          @media (max-width: 991px){
            font-size: 20px;
          }
          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            height: 4px;
            width: 128px;
            display: block;
            background: var(--main-color);
            box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
            border-radius: 10px;
            @media (max-width: 991px){
              left: 50%;
              transform: translateX(-50%);
            }
          }
          span {
            font-family: 'Font Black';
          }
        }
      } 
      &-body {
        @media (max-width: 991px){
          .col-6 {
            &:nth-child(odd){
              padding-right: 7.5px;
            }
            &:nth-child(even){
              padding-left: 7.5px;
            }
          }
        }
        .links {
          position: relative;
          height: 276px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 3px 3px 10px #00000029;
          overflow: hidden;
          border-radius: 5px;
          transition: 500ms;
          text-decoration: none !important;
          @media (max-width: 991px){
            height: 156px;
            margin-bottom: 15px;
          }
          &:hover,&:focus {
            cursor: pointer;
            .links-image {
              transform: scale(1.05);
            }
          }
          &-image {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            background-repeat: no-repeat !important;
            background-position: center !important;
            background-size: cover !important;
            border-radius: 5px;
            transition: 500ms;
          }
          p {
            font-size: 26px;
            font-family: 'Font Black';
            color: var(--white);
            text-transform: uppercase;
            z-index: 1;
            @media (min-width: 992px) and (max-width: 1199px){
              font-size: 20px;
            }
            @media (max-width: 991px){
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  &-new-ones {
    padding: 80px 0px;
    background: #F3F5F8;
    @media (max-width: 991px){
      padding: 30px 0px;
    }
    &-itens {
      &-header {
        text-align: left;
        margin-bottom: 50px;
        @media (max-width: 991px){
          text-align: center;
        }
        .title {
          position: relative;
          font-size: 40px;
          font-family: 'Font Regular';
          color: #2F2F32;
          margin-bottom: 0;
          padding-bottom: 10px;
          @media (max-width: 991px){
            font-size: 20px;
          }
          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            height: 4px;
            width: 67px;
            display: block;
            background: var(--main-color);
            box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
            border-radius: 10px;
            @media (max-width: 991px){
              left: 50%;
              transform: translateX(-50%);
            }
          }
          span {
            font-family: 'Font Black';
          }
        }
      } 
      &-body {
        .content-btn {
          display: flex;
          justify-content: flex-end;
          @media (max-width: 991px){
            justify-content: center;
          }
          .btn-view-plus {
            height: 63px;
            width: 155px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #43433F;
            border: 1px solid #43433F;
            border-radius: 10px;
            box-shadow: 0px 3px 5px rgba(0,7,4,0.36);
            font-size: 20px;
            font-family: 'Font Medium';
            color: var(--white);
            text-decoration: none !important;
            transition: 500ms;
            @media (max-width: 991px){
              font-size: 16px;
              height: 50px;
              width: 130px;
            }
            &:hover,&:focus {
              cursor: pointer;
              background: var(--white);
              color: #43433F;
            }
          }
        }
      }
    }
  }
  &-blog {
    padding: 80px 0px;
    background: var(--white);
    @media (max-width: 991px){
      padding: 30px 0px;
    }
    &-itens {
      &-header {
        text-align: left;
        margin-bottom: 50px;
        @media (max-width: 991px){
          text-align: center;
        }
        .title {
          position: relative;
          font-size: 40px;
          font-family: 'Font Regular';
          color: #2F2F32;
          margin-bottom: 0;
          padding-bottom: 10px;
          @media (max-width: 991px){
            font-size: 20px;
          }
          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            height: 4px;
            width: 100%;
            display: block;
            background: var(--main-color);
            box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
            border-radius: 10px;
            @media (max-width: 991px){
              width: 62px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          span {
            font-family: 'Font Black';
          }
        }
      } 
      &-body {
        .content-btn {
          display: flex;
          justify-content: flex-end;
          .btn-go-to-blog {
            display: block;
            font-size: 18px;
            font-family: 'Font Regular';
            color: #2F2F32;
            transition: 500ms;
            &:hover,&:focus {
              cursor: pointer;
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}
</style>