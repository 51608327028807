<template>
    <section class="content-home-solutions">
        <div class="content-home-solutions-itens container">
          <div class="content-home-solutions-itens-header">
            <h2 class="title" v-html="$options.filters.removeP($t('home.title_section_solutions'))"></h2>
          </div>
          <div class="content-home-solutions-itens-body">
            <div class="row">
              <div class="col-6 col-lg-3">
                <router-link tag="a" :to="{name: 'vehicleParts'}" class="links">
                  <div class="links-image" :style="{'background': `url('${image1}')`}"></div>
                  <p>{{ $t('home.title_parts')|stripHtml }}</p>
                </router-link>
              </div>
              <div class="col-6 col-lg-3">
                <router-link tag="a" :to="{name: 'vehicleSchedule'}" class="links">
                  <div class="links-image" :style="{'background': `url('${image2}')`}"></div>
                  <p>{{ $t('home.title_services')|stripHtml }}</p>
                </router-link>
              </div>
              <div class="col-6 col-lg-3">
                <router-link tag="a" :to="{name: 'directSales'}" class="links">
                  <div class="links-image" :style="{'background': `url('${image3}')`}"></div>
                  <p>{{ $t('home.title_direct_sales')|stripHtml }}</p>
                </router-link>
              </div>
              <div class="col-6 col-lg-3">
                <router-link tag="a" :to="{name: 'vehicleConsortium'}" class="links">
                  <div class="links-image" :style="{'background': `url('${image4}')`}"></div>
                  <p>{{ $t('home.title_consortium')|stripHtml }}</p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
</template>
<script>
import { getThemeActive } from '@/LayoutTheme'
export default {
    mounted(){
        let layout = getThemeActive();
        this.image1 = layout.imageHome.image1;
        this.image2 = layout.imageHome.image2;
        this.image3 = layout.imageHome.image3;
        this.image4 = layout.imageHome.image4;
    },
    data(){
        return{
            image1: '',
            image2: '',
            image3: '',
            image4: '',
        }
    }
}
</script>