<template>
    <section v-if="blogs && blogs.length > 0" class="content-home-blog">
        <div class="content-home-blog-itens container">
          <div class="content-home-blog-itens-header">
            <h2 class="title">Blog</h2>
          </div>
          <div class="content-home-blog-itens-body">
            <div class="row">
              <div class="col-12 col-sm-6 col-lg-4" v-for="n in (3)" :key="n">
                <CardBlog />
              </div>
            </div>
            <div class="content-btn">
              <router-link tag="a" :to="{name: 'Blog'}" class="btn-go-to-blog">
                Ir para o blog
              </router-link>
            </div>
          </div>
        </div>
      </section>
</template>
<script>
import CardBlog from "@/components/cards/CardBlog.vue";
import { mapActions, mapGetters } from 'vuex';

export default {
    props:{
      limit: Number,
    },
    components:{
        CardBlog,
    },
    computed: {
      ...mapGetters('Site', ['loadingBlog', 'blogs'])
    },
    methods: {
      ...mapActions('Site', ['loadBlogs'])
    },
    mounted(){
      this.loadBlogs({
        limit: this.limit,
      });
    }
}
</script>