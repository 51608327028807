<template>
  <section class="content-banner">
    <div class="content-banner-itens">
      <vue-skeleton-loader          
          :width="1920"
          :height="700"
          animation="fade"
          v-if="loadingBanner"
        />
      <swiper v-else class="swiper-banner" :options="swiperOptions">
        <swiper-slide v-for="banner in banners" :key="banner.id">
          <div class="content-banner-itens-image d-none d-lg-block" :style="{'background': `url('${banner.desktop}')`}">
          </div>
          <div class="content-banner-itens-image d-lg-none" :style="{'background': `url('${banner.mobile}')`}">
          </div>
        </swiper-slide>
        
        <div class="swiper-pagination swiper-pagination-banner" slot="pagination"></div>
      </swiper>
      <!--  -->
        <FormCotation v-if="banners && banners.length > 0" />
      <!--  -->
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import FormCotation from "@/components/Forms";
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Swiper,
    SwiperSlide,
    FormCotation,
  },
  directives: {
    swiper: directive,
  },
  computed: {
    ...mapGetters('Site', ['loadingBanner', 'banners']),
  },
  methods: {
    ...mapActions('Site', ['loadBanners']),
  },
  mounted(){
    this.loadBanners();
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 800,
        watchOverflow: true,
        clickable: true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: ".swiper-pagination-banner",
          type: "bullets",
          clickable: true,
        },
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
			},      
    };
  },
};
</script>

<style lang="scss">
.content-banner {
  &-itens {
    position: relative;
		&-image {
			height: 740px;
			width: 100%;
			background-position: center !important;
			background-size: cover !important;
			background-repeat: no-repeat !important;
			@media (min-width: 992px) and (max-height: 800px) {
				height: 500px;
			}
			@media (max-width: 991px){
				height: 400px;
			}
    }
  }
}
.content-form-cotation {
  position: absolute;
  top: 30px;
  max-width: 330px;
  z-index: 2;
  background: #FBFBFB;
  border-radius: 15px;
  box-shadow: 0px 3px 30px rgba(0,0,0,0.16);
  &-header {
    height: 50px;
    width: 100%;
    background: var(--main-color);
    border-radius: 15px 15px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
      font-size: 16px;
      font-family: 'Font Bold';
      text-transform: uppercase;
      color: var(--white);
      margin-bottom: 0;
    }
  }
  &-menu {
    display: flex;
    align-items: center;
    padding: 15px;
    .content-links {
      padding: 10px 0px;
      text-decoration: none !important;
      width: 25%;
      border-right: 1px solid #F3F3F3;
      border-bottom: 3px solid transparent;
      transition: 500ms;
      &:hover,&:focus {
        cursor: pointer;
        opacity: 0.6;
      }
      &:last-child {
        border-right: 0;
      }
      svg {
        transition: 500ms;
        fill: #ccc;
      }
      p {
        font-size: 12px;
        font-family: "Font Regular";
        color: #cccccc;
        margin-bottom: 0;
        margin-top: 10px;
        transition: 500ms;
      }
    }
    .active-tab {
      background: #F5F5F5;
      border-bottom: 3px solid var(--main-color);
      border-right: 1px solid transparent;
      svg {
        fill: var(--main-color);
      }
      p {
        color: var(--main-color);
        font-family: 'Font Bold';
      }
    }
  }
}
.swiper-banner {
  .swiper-pagination {
    &-bullet {
      width: 12px;
      height: 4px;
      background: var(--white);
      opacity: 1;
      cursor: pointer;
      border-radius: 0;
      margin: 0px 7.5px !important;
      outline: none !important;
      @media (max-width: 991px){
        background: #e0e0e0;
      }
    }
    &-bullet-active {
      background: var(--main-color);
      width: 30px;
    }
  }
}
</style>
